import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { Button, Card } from 'react-bootstrap'

const Post = ({ post }) => {
  const { featuredImage, cover_image, cover_color } = post.frontmatter
  const image = featuredImage
    ? featuredImage.childImageSharp.fluid.src
    : cover_image

  console.log('image: ', image)

  return (
    <>
      <Card
        className="my-3"
        style={{
          backgroundColor: cover_color,
          backgroundSize: 'cover',
          backgroundImage: `url("${image}")`,
        }}
      >
        <Card.Img variant="top" src={post.frontmatter.cover_image} />
        <Card.Body>
          <Card.Title className="bg-light p-2">
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </Card.Title>
          <Card.Text className="bg-light p-2 my-4">{post.excerpt}</Card.Text>
          <Link className="text-white" to={post.fields.slug}>
            <Button variant="primary">Read more</Button>
          </Link>
        </Card.Body>
      </Card>
      <hr />
    </>
  )
}

export default ({ data }) => (
  <Layout>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <Post key={node.id} post={node} />
    ))}
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            cover_color
            cover_image
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
